import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Yogesh Kumar',
    status : 'Gym Trainer'
  },
  {
    image: image2,
    review: ' Its Your Workout: Your Time. Your Body. Own It ',
    name: 'Shyam Jaiswal',
    status: 'COACH'
  },
  {
    image : image3,
    review:' A little progress each day adds up to big results.',
    name: 'Upendra Yadav',
    status: "CUSTOMER"
  }
];
