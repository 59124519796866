import './App.css';
import Hero from './Components/Hero/Hero';
import Join from './Components/Join/Join';
import Plans from './Components/Plans/Plans';
import Program from './Components/Program/Program';
import Reason from './Components/Reasons/Reason';
import Testimonials from './Components/Testimonials/Testimonials';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <main className='App'>
        <Hero/> 
        <Program/>
        <Reason/>
        <Plans/>
        <Testimonials/>
        <Join/>
        <Footer/>
    </main>
  );
}

export default App;
